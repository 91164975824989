@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.App{
height: 90%;
display: flex;
justify-content: center;
align-items: center;
}

button { 
  cursor: pointer;
font-size: 20px;
box-shadow: 2px 2px #ec7210;

}

h4{
color: #000000;
font-size: 20px;
}


.heading{
font-size: 80px;
text-align: center;
text-decoration: underline;
font-family: 'Pacifico', cursive;
color: #1453dc;
}

p{
font-size: 20px;
color: #131313;
}

#ip{
color: #ec7210;
font-size: 40px;
}
